import React from "react";
import "./App.css";

function Welcome() {

  return (
    <div className="homep">
      <div className="homep-title">
        <h1>
          <span>Welcome</span>
        </h1>
      </div>
    </div>
  );
}

export default Welcome;