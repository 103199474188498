import React from "react";
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import "../stylesheets/Blog.css";

const demoData = [
  {
      id: '1',
      title: 'Brainstorming the F40 Mailbox',
      subtitle: 'test subtitle',
      author: 'Daniel Parrish',
      published: 'Jan 12th, 2023',
      previousArticleID: '',
      nextArticleID: '',
      content: '<p>I always love seeing custom mailboxes, so when my friend asked me if I could make a car-themed mailbox for him, I <strong>instantly</strong> accepted.</p>'
  },
  {
    id: '2',
    title: 'F40 Mailbox Sketches',
    subtitle: 'test subtitle 2',
    author: 'Daniel Parrish',
    published: 'Jan 13th, 2023',
    previousArticleID: '',
    nextArticleID: '',
    content: '<p>I always love seeing custom mailboxes, so when my friend asked me if I could make a car-themed mailbox for him, I <strong>instantly</strong> accepted.</p>'
  }
];

function Blog() {

  const { id } = useParams();

console.log(id);


  var content = <div>404 not found</div>;

  if(isNaN(id))
    content = <ArticleList/>;
  else{
    content = <ArticlePage articleID={id}/>;
  }

  return (
    <div className="blog-root">
      {content}
    </div>
  );
}

function ArticleList() {

  //retrieve actual data via rest api
  
  //process list of articles
  var articleList = [];
  for(var i in demoData){
    articleList.push(<ArticleLink key={i} articleID={demoData[i].id} title={demoData[i].title} subtitle={demoData[i].subtitle} published={demoData[i].published}/>);
  }


  return (
    <div className="article-list">
      <h2>Browse Articles</h2>
      {articleList}
    </div>
  );
}

function ArticleLink(props){
  return (
    <div className="article-link-box">
    <Link className="article-link" to={"/blog/" + props.articleID}>
      <div className="article-link-title">{props.title}</div>
      <div className="article-link-subtitle">{props.subtitle}</div>
      <div className="article-link-published">{props.published}</div>
    </Link>
    </div>
  );
}

function ArticlePage(props){

  var data = demoData.find( obj => obj.id == props.articleID );

  return (
    <div className="article-page">
      <h1>{data.title}</h1>
      <h3>{data.subtitle}</h3>
      <div>{data.author}</div>
      <div>{data.published}</div>
      <br/>
      <div>{parse(data.content)}</div>
    </div>
  );
}















export default Blog;