import React from "react";
import "./App.css";
import Welcome from "./Welcome";
import { Link } from 'react-router-dom';
//import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [data, setData] = React.useState(null);

  // React.useEffect(() => {
  //   fetch("/api")
  //     .then((res) => res.json())
  //     .then((data) => setData(data.message));
  // }, []);

  return (
    <div>
      <Welcome/>
      <div className="homelinks">
        <div className="quarter-spacing">
          <div className="quarter-link">About Me</div>
        </div>
        <div className="quarter-spacing">
          <div className="quarter-link"><Link to='/blog'>Blog</Link></div>
        </div>
        <div className="quarter-spacing">
          <div className="quarter-link">Projects</div>
        </div>
        <div className="quarter-spacing">
          <div className="quarter-link">Media</div>
        </div>
        </div>
    </div>
  );
}

export default App;
